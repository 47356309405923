.input-primary {
  width: 260px;
  height: 38px;
  background: initial;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.32);
  margin-bottom: 3%;
  font-size: 25px;
}
.input-primary::placeholder {
  text-align: center;
  font: normal normal 300 20px/30px Arial;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 0.32;
  font-size: 22px;
}

.input-orderNr {
  width: 260px;
  height: 38px;
  background: initial;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.32);
  margin-bottom: 5%;
  font-size: 25px;
}
.input-orderNr::placeholder {
  opacity: 0.32;
  font-size: 25px;
}

.w-30 {
  width: 30%;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input {
  font-size: 25px;
  outline: none;
  font-size: 2rem;
  color: white;
  text-align: center;
}
input::placeholder {
  font-size: 25px;
  outline: none;
  font-size: 2rem;
  color: white;
  text-align: center;
  opacity: 1;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  cursor: pointer;
  width: 20vw;
  height: 5vh;
  border-radius: 44px;
  background: white;
  color: #e10008;
  font-size: 1.3rem;
  box-shadow: 0px 2px 3px #00000029;
  opacity: 1;
}
@media (min-width: 1400px) {
  .custom-file-upload {
    width: 12vw;
  }
}
@media (max-width: 400px) {
  .input-primary {
    width: 200px;
  }
  .input-primary::placeholder {
    font-size: 20px;
  }
}
