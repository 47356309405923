.ahutest-form--label {
  margin-top: 2%;
  font-size: 23px;
}

.ahutest-from--title {
  font-size: 40px;
  margin-top: 5%;
  width: 100%;
  max-width: 1000px;
}
.left {
  display: flex;
  justify-content: flex-start;
  width: 525px;
  margin-bottom: 2%;
  align-items: center;
}

.ahutest-form__label--grid {
  font-size: 40px;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 525px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
  grid-template-areas: "label input";
}

.ahutest-form__label--grid2 {
  font-size: 40px;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 525px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  align-items: center;
  grid-template-areas: "label icon1 icon2 icon3";
  grid-template-rows: auto;
}

.ahutest-form__label--grid3 {
  font-size: 40px;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 675px;
  display: grid;
  grid-template-columns: 3fr 1fr 1fr 1fr;
  align-items: center;
  grid-template-areas: "label label label" "input1 input2 input3";
  grid-template-rows: auto;
}

.ahutest-form__label-info-button--grid {
  font-size: 40px;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 525px;
  display: grid;
  grid-template-columns: 5fr 1fr 2fr 2fr 2fr;
  align-items: center;
  grid-template-areas: "label icon0 icon1 icon2 icon3";
  grid-template-rows: auto;
}

.ozTest-form__label-info-button--grid {
  font-size: 40px;
  margin-top: 3%;
  margin-bottom: 2%;
  width: 525px;
  display: grid;
  grid-template-columns: 5fr 1fr 2fr 2fr;
  align-items: center;
  grid-template-areas: "label icon0 icon1 icon2";
  grid-template-rows: auto;
}

.grid-item-row2-label {
  grid-area: label2;
  text-align: left;
  margin-right: 10%;
  font-size: 12px;
  margin-bottom: 0;
  justify-self: end;
  align-self: center;
}

.grid-item-info-button {
  grid-area: icon0;

  background: initial;
  border: none;
  cursor: pointer;
}

.grid-item-label {
  grid-area: label;
  text-align: left;
  margin-left: 10%;
  font-size: 23px;
  margin-bottom: 0;
}

.grid-item-input {
  grid-area: input;
  height: 25px;
  width: 25px;
  background: initial;
  border: none;
  cursor: pointer;
}

.grid-item-input1 {
  grid-area: icon1;
  height: 25px;
  width: 25px;
  background: initial;
  border: none;
  cursor: pointer;
}

.grid-item-input2 {
  grid-area: icon2;
  height: 25px;
  width: 25px;
  background: initial;
  border: none;
  cursor: pointer;
}

.grid-item-input3 {
  grid-area: icon3;
  height: 25px;
  width: 25px;
  background: initial;
  border: none;
  cursor: pointer;
}

.ahutest-container {
  margin-top: 4%;
  animation: fadeIn 500ms ease-in-out;
}

.ahutest-form__row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80%;
}

.ahutest-form__row--grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: center;
  width: 100%;
}

.ahutest-form__row--grid-name {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: center;
  width: 100%;
}

.ahutest-form__row--grid-inputs {
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-items: center;
  align-items: center;
  width: 100%;
}

.ahutest-form__input {
  width: 400px;
  height: 38px;
  background: initial;
  border: none;
  border-bottom: 2px solid rgba(255, 255, 255, 0.32);
  margin-bottom: 5%;
  font-size: 25px;
}
.ahutest-form__input::placeholder {
  opacity: 0.32;
  font-size: 20px;
}

.ahutest-cooler-from__label {
  margin-left: 0;
  font-size: 25px;
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.--value {
  width: 100px;
}

.select {
  font-size: 22px;
}

.option {
  background: #fff;
  color: #e10008;
}

option:checked,
option:selected,
option:hover {
  background: rgba(
    59,
    50,
    199,
    0.568
  ); /* sets the background color of the selected option */
}

.smaller::placeholder {
  font-size: 10px;
}

.range::placeholder {
  font-size: 13px;
}

.time-picker > label[for^="mui-"] {
  color: rgba(255, 255, 255, 0.32);
}

.time-picker fieldset {
  border: none;
}
.time-picker input {
  font-size: 25px;
  width: 100%;
  height: 15px;
  color: #fff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.32);
  margin: 0px 0px 15px 0px;
}

.time-picker {
  width: 30%;
}

.css-154cs8t-MuiPickersYear-root > button {
  background-color: lightgray;
}

.ahutest-form__input--checkbox {
  height: 25px;
  width: 25px;
  background: initial;
  border: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@media (max-width: 1920px) {
  .ahutest-container {
    margin-top: 5%;
  }
}
@media (max-width: 800px) {
  .ahutest-container {
    margin-top: 10%;
  }
  .ahutest-from--title {
    width: 85%;
  }
}
@media (max-width: 400px) {
  .ahutest-container {
    margin-top: 14%;
  }
  .ahutest-from--title {
    margin-top: 7%;
    font-size: 30px;
  }
  .ahutest-form__label--grid2 {
    width: 99%;
  }
  .ahutest-form__input {
    width: 250px;
  }
  .--value {
    width: 60px;
  }
}
