.insert-picture-label{
    opacity: 0.32;
    font-size: 20px;
}

.test-row{
    margin-top: 3%;
    margin-bottom: 2%;
    width: 675px;
    display: flex;
    justify-content: center;

}

.test-row--item{

    text-align: center;

    font-size: 23px;

}