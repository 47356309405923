
.select-order-form__grid{
    width: 100%;
    display: grid;
    grid-template-columns:  1fr 8fr 1fr; 
    align-items: center;
    grid-template-areas: "icon1 label icon2";         
    grid-template-rows: auto;
    margin-top: 4%;

}

.select-order-grid__label{
    grid-area: label;
    text-align: center;
    border: 2px solid white;
    border-radius: 3px;
    display: flex;
    padding: 5px;
    cursor: pointer;
    height: 100%;
}

.select-order-grid__icon1{
    grid-area: icon1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.select-order-grid__icon2{
    grid-area: icon2;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.active {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 10px;
    border-color: white;
    color: #e10008;
}

.select-order-grid__label.active div {
    color: #e10008;
}
