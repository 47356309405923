/*HEADERS & DIVIDERS*/
.header-container{
    margin-bottom: 10px;
    color:white;
    z-index: 99;
  }
/*TESTiQ logo*/
  .header { 
    height: 128px;
    display: flex;
    position: absolute;
    top: 4vh;
    left: 0%; 
    text-align: center; 
    width: 100%;
  }
  .header-logo{
    font-size: 66px;
    width: 100%;
  }
  .header-logo-img{

  }
  
  .subheader {
    display: flex;
    justify-content: space-between;
    min-height: 49px;
    align-items: center !important;
    padding:5px 50px 5px 50px;
    width:100vw;
  }
  .subheader-logout-container{
    margin-left:10px;
    display: flex;
    gap:25px;
  }
  .subheader-logout{
    cursor:pointer;
    font-size: 20px;
    color: #b3b3b3;
  }
  
  @media(max-width: 400px){
    .header{
      top: 6vh;
    }
    .subheader{
      padding: 5px 0px 5px 0px;
    }
    .header-logo-img{
      width:60vw;
    }
  }
