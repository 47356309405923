.button-primary {
    background: inherit;
    border-radius: 44px;
    border-color: white;
    border-width: 2px;
    width: 100px;
    box-shadow: none !important;
    margin: 0 1% 0 1%;
}

.button-primary:active,
.button-primary:focus {
    border-color: white;
    background: inherit;
    transition: 250ms ease-in-out;
}

.button-primary:hover {
    color: #e10008;
    background: white;
    border-color: white;
    transition: 250ms ease-in-out;
}

.smoketest-button-selection {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 5vh;
    margin-top: 4%;
    padding: 5px;
    background: inherit;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    font-size: 25px;
    text-transform: uppercase;
    height: 70px;
}

.-active {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    color: #e10008;
    transition: 250ms ease-in-out;
}

.smoketest-button-selection:hover,
.smoketest-button-selection:active,
.smoketest-button-selection:focus {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    color: #e10008;
    transition: 250ms ease-in-out;
    height: 70px;
}

.button-selection-1 {
    background: inherit;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    height: 5vh;
    font-size: 25px;
    text-transform: uppercase;
}

.button-selection-1-active {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    color: #e10008;
}

.button-selection-1:hover,
.button-selection-1:active,
.button-selection-1:focus {
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 3px solid #FFFFFF;
    border-radius: 10px;
    opacity: 1;
    color: #e10008;
    transition: 250ms ease-in-out;
}

.disable:disabled {
    display: none;
}

.button-lengthTest {
    background: inherit;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 44px;
    border-color: white;
    opacity: 1;
    color: white;
    margin-left: 3%;
}

.button-lengthTest:hover,
.button-lengthTest:active,
.button-lengthTest:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    border-radius: 44px;
    border-color: white;
    opacity: 1;
    color: #e10008;
    margin-left: 3%;
    transition: 250ms ease-in-out;
}

@media (min-width: 1600px) {
    .button-selection-1 {
        width: 10vw;
    }
}

@media (max-width: 1200px) {
    .button-selection-1 {
        width: 15vw;
    }
}

@media (max-width: 725px) {
    .button-selection-1 {
        width: 25vw;
    }
}

@media (max-width: 400px) {
    .button-selection-1 {
        width: 45vw;
        font-size: 17px;
    }

    .smoketest-button-selection {
        font-size: 17px;
        height: 8vh;
    }
}