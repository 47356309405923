.container {
    block-size: fit-content;
    padding-left: 0px;
    padding-right: 0px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden;
}

.container-background {
    background-image: url(../graphics/images/Background_1_gradient.jpg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

@media(max-width: 815px) {
    .container-background {
        background-size: cover;
    }
}