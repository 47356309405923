.flexboxAlignedRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flexboxAlignedColumn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.measurementTest-Row {
  width: 100%;
}
.rigidity-test--row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 400px) {
  .measurementTest-Row {
    width: 70%;
  }
  .rigidity-test--row {
    width: 380px;
  }
}
