@mixin theme($theme: DarkGray) {
    background: $theme;
    box-shadow: 0 0 1px rgba($theme, .25);
    color: #fff;
  }
  
  .info {
    @include theme;
  }
  .alert {
    @include theme($theme: DarkRed);
  }
  .success {
    @include theme($theme: DarkGreen);
  }