.selectContainer {
  height: 38px;
  width: 260px;
}

.orderData {
  /* width: 100%; */
  width: 85%;
}

.itemData {
  width: 100%;
  font-size: 25px;
}

.orderDataOrders {
  border: 2px solid white;
  margin-top: 4%;
  border-radius: 3px;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 5px;
}

.orderDataOrders:hover {
  cursor: pointer;
}

.orderDataOrders.active {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 3px #00000029;
  border-radius: 10px;
  border-color: white;
  color: #e10008;
}

.orderDataOrders.active div {
  color: #e10008;
}

.mainPageForm {
  margin-top: 1%;
  width: 600px;
  animation: fadeIn 500ms ease-in-out;
}

.navigation-buttons-container {
  position: relative;
  margin-top: 5vh;
  bottom: 5%;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 500ms ease-in-out;
}

.mainPageForm-label {
  font-size: 40px;
}

.portal-page__label {
  font-size: 40px;
}

.mainPageForm-label2 {
  font-size: 40px;
  margin-top: 5%;
}

.material-label {
  font-size: 30px;
  margin-top: 5%;
  width: 250px;
}

.mainPageForm-label3 {
  font-size: 40px;
  margin-bottom: 3%;
  margin-top: 3%;
}

.smallLabel {
  margin-left: 2%;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.mainPageLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  animation: fadeIn;
  animation-duration: 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.progressBar {
  margin-top: 20%;
}

.material-textarea-description {
  margin: auto;
  width: 400px;
  height: 80px;
  color: gray;
  text-align: left;
}

.message-testName-unkown {
  margin-top: 10%;
  font-size: 25px;
}

.smokeTest-FileUploadArea {
  width: 70%;
  flex-direction: column;
  margin-top: 10%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.mainPageForm-select {
  width: 200px;
  cursor: pointer;
  outline: none !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 2px solid #ffffff;
  color: #e10008;
  height: 70px;
  font-size: 25px;
  border-radius: 10px;
}
.mainPageForm-select > fieldset {
  border: none;
}
.mainPageForm-select > option {
  border: none;
  color: #e10008;
  cursor: pointer;
  height: 50px;
}
.mainPageForm-select:focus-visible,
.mainPageForm-select:focus {
  outline: none !important;
  border: 2px solid #ffffff;
  border-radius: 10px;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #e10008;
}

.mainPageForm-checkbox {
  color: white;
  width: 25px;
  height: 25px;
  outline: none !important;
}

.mainPageForm-checkbox:checked {
  accent-color: #b3b3b3;
}

@media (max-width: 800px) {
  .portal-page__label {
    margin-top: 10%;
  }
}
@media (max-width: 400px) {
  .itemData {
    font-size: 20px;
  }

  .progressBar {
    width: 80vw;
    margin-top: 20%;
  }

  .mainPageForm-label {
    font-size: 28px;
  }
  .portal-page__label {
    margin-top: 15%;
    font-size: 28px;
  }

  .mainPageForm-label2 {
    font-size: 28px;
  }

  .mainPageForm-label3 {
    font-size: 28px;
  }

  .material-label {
    font-size: 22px;
  }

  .orderData {
    width: 55%;
  }

  .material-textarea-description {
    width: 350px;
  }
}
