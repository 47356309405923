table {
    margin-top: 10%;
    width: 30vw;
}

tr {
    height: 4vh;
}

th {
    font-size: 50px;
    text-align: left;
}

.firstTd {
    width: 100px;
}

.materialStatusTd{
/*     display: flex;
    align-items: center; */
    text-align: left;
}

tr td {
    text-align: left;
}

@media (max-width: 1280px) {
    table {
        width: 50vw;
    }
}