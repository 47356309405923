.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

html * {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color:white; 
/*   color:gray; */
  text-align: center;
  font-family: Banschrift;
/*   font-family: HelveticaNeueLTPro-Roman; */
} 

@font-face {
  font-family: 'Banschrift';
  font-style: normal;
  font-weight: normal;
  src: local('Banschrift'), url(./fonts/BAHNSCHRIFT.TTF) format('truetype')
} 
@font-face {
  font-family: 'HelveticaNeueLTPro-Roman';
  font-style: normal;
  font-weight: normal;
  src: local('HelveticaNeueLTPro-Roman'), url(./fonts/HelveticaNeueLTPro-Roman.ttf) format('truetype')
} 

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

html, body {
  overscroll-behavior: none;
}


