.loading-container{
    width: 100vw;
    /* top: 12px; */
    top:0%;
    /* 15% */
    max-width: 199%;
    position: fixed;    
}
.loading-spinner-container{
    display: flex;
    justify-content: center;
}

.loading-text-container{ 
    display: flex;
    justify-content: center;
}