.sepu-test--input {
  width: 400px;
}
.sepu-test--input::placeholder {
  font-size: 23px;
}

.xs-placeholder::placeholder {
  font-size: 16px;
}
